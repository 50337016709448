// i18next-extract-mark-ns-start support-academia

import { SUPPORT_ACADEMIA_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import ContactForm from '@components/ContactForm';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const AcademiaPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const ignite = getImage(data.ignite.childImageSharp.gatsbyImageData);
	const wave = getImage(data.wave.childImageSharp.gatsbyImageData);
	const valdyn = getImage(data.valdyn.childImageSharp.gatsbyImageData);
	const vectis = getImage(data.vectis.childImageSharp.gatsbyImageData);
	const image = getImage(data.image.childImageSharp.gatsbyImageData);


	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__content" layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>University teaching and research licenses</h2>
						<p>
							Dedicated to promoting careers in STEM through applied
							learning, Realis Simulation offers cost effective academic
							licensing grants to students and institutions worldwide.
							We&rsquo;re interested and invested in creating the engineers
							of the future, and we&rsquo;re proud to offer a cost effective
							academic licensing programme in order for students and
							researchers to gain hands-on software experience.
						</p>

						<h2>Formula SAE racing grants</h2>

						<p>
							Focused on promoting careers in STEM through motorsport design academic licenses are available for
							Formula SAE (FSAE) and other race series racing teams world-wide for the following products:
						</p>
					</Trans>

					{ignite && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={ignite}
								alt="IGNITE"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_2">
						<p>
							Teams competing in hybrid and electric competitions use <a href={LINKS.PRODUCTS_IGNITE}>IGNITE</a> for full-vehicle-system simulation in
							order to better analyse vehicle performance, fuel consumption and energy flow analysis.
						</p>
					</Trans>

					{wave && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={wave}
								alt="WAVE"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_3">
						<p>
							Realis Simulation's class-leading engine performance simulation software <a href={LINKS.PRODUCTS_WAVE}>WAVE</a> is
							used by almost 100% of our sponsored FSAE teams.
						</p>
					</Trans>

					{valdyn && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={valdyn}
								alt="VALDYN"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_4">
						<p>
							Designed specifically for valvetrain and drive-system analysis, experienced teams look to
							use <a href={LINKS.PRODUCTS_VALDYN}>VALDYN</a> to focus on mechanical systems.
						</p>
					</Trans>

					{vectis && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={vectis}
								alt="VECTIS"
								className="icon-image"
							/>
						</div>
					)}

					<Trans i18nKey="content_5">
						<p>
							Having gained experience with WAVE, many teams use <a href={LINKS.PRODUCTS_VECTIS}>VECTIS</a>,
							for detailed 3D flow analysis in manifolds.
						</p>
						<h3></h3>
						<p>
							We offer a 5-seat IGNITE, WAVE, VALDYN and VECTIS FSAE license package for teams,
							which includes a limited amount of technical support.
						</p>
					</Trans>

					{image && (
						<p>
							<GatsbyImage image={image} alt="FSAE" />
						</p>
					)}

					<Trans i18nKey="content_6">
						<h3>Testimonial</h3>
						<p>
							Choosing a STEM career focus without much experience or
							exposure can be intimidating, but as Mitchell Fink, Virginia
							Tech Motorsport Engine team member, pointed out when using Ricardo Software, now Realis Simulation:
						</p>
						<p>
							<i>
								“Students looking into the engineering field should strive
								to be hands-on. Ricardo Software&rsquo;s WAVE was the first
								software simulation tool I used to model engines and,
								because of that hands-on experience, I have advanced my
								education in mechanical engineering and am prepared for a
								career in the automotive industry. I never would have chosen
								an automotive career path without FSAE and Ricardo Software.”
							</i>
						</p>
						<hr />
						<p>
							<span>
								If you are interested in joining our team we encourage graduates to apply.
								To find out more please go to our <a href={LINKS.ABOUT_CAREERS}>careers page.</a>
							</span>
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("License request")}
						className="widget__sibling__pages">
						<AssetList>
							{SUPPORT_ACADEMIA_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default AcademiaPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["support-academia", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}		
		banner: file(relativePath: { eq: "content/academia/shutterstock_298735559_edited.jpg" }) {
			...imageBreaker
		}
		ignite: file(relativePath: { eq: "content/products/ignite/rs_ignite.png" }) {
			...imageStandard
		}
		wave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
			...imageStandard
		}
		valdyn: file(relativePath: { eq: "content/rs_valdyn.png" }) {
			...imageStandard
		}
		vectis: file(relativePath: { eq: "content/products/vectis/rs_vectis.png" }) {
			...imageStandard
		}
		image: file(relativePath: { eq: "content/fsae.png" }) {
			...imageStandard
		}
	}
`;
